import { Injectable } from '@angular/core';
import { collection, collectionData, Firestore, limit, query, where } from '@angular/fire/firestore';

import { Billing } from 'cip';
import { filter, map, Observable, shareReplay } from 'rxjs';
import { CollectionsService } from './collections/collections.service';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  constructor(
    private firestore: Firestore,
    private collectionsService: CollectionsService
  ) {}

  // Get User Subscription
  public getUserSubscription$(user_id: string): Observable<Billing> {
    const path = this.collectionsService.userBillingCol();
    const colRef = collection(this.firestore, path);

    const billingQuery = query(colRef, where('app_user_id', '==', user_id), limit(1));

    return collectionData(billingQuery, { idField: 'id' }).pipe(
      map(([billing]) => billing as Billing),
      filter((billing) => !!billing),
      shareReplay(1)
    );
  }
}
